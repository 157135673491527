.cube-menu {
  perspective: 600px;

  .cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-100px);
    transition: transform 1s;

    .cube-face {
      position: absolute;
      cursor: pointer;
      display: flex;
      justify-content: center;
      user-select: none; } } }
