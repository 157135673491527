$low-res: 650px;
$amate-color: #e9a976;
$wood-color: #472000;
$day-color: #ddd;
$night-color: #111;

.cube-menu {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 99; }

.cube-face {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  border: solid 2px black;
  border-radius: 5px;
  white-space: pre;
  padding-top: .3rem; }

.cube-face-amate {
  color: brown;
  background-image: url(../themes/pattern.png);
  border-color: #0d1599; }

.cube-face-wood {
  color: #fdff92;
  background-image: url(../themes/wood-tile.jpg);
  border-color: darken($wood-color, 30%); }

.cube-face-day {
  background-color: $day-color;
  border-color: darken($wood-color, 30%); }

.cube-face-night {
  color: white;
  background-color: $night-color;
  border-color: lighten($night-color, 30%); }

header {
  position: static;
  top: 5px;
  left: 1rem;
  font-size: 2.3rem;
  font-family: monospace;
  font-weight: bold;
  letter-spacing: 3px;
  text-align: center;
  padding: 0 10px;
  border: solid 2px black;

  &.amate {
    color: #1b3d8a;
    background-image: url(../themes/pattern.png);
    border-color: #0d1599; }

  &.wood {
    color: #fdff92;
    background-image: url(../themes/wood-tile.jpg);
    border-color: #e06500; }

  &.day {
    color: black;
    background-color: $day-color;
    border-color: darkgrey; }

  &.night {
    color: white;
    background-color: $night-color;
    border-color: lighten($night-color, 30%); }

  @media only screen and (max-width: $low-res) {
    position: absolute;
    border-radius: 10px; } }

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  border-top: solid 2px white;
  padding: 2px 0 4px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.75);

  a {
    color: inherit;
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: bold;

    &::before {
      content: '📧 ';
      font-size: 1.2em; } }

  &.amate {
    color: #1b3d8a;
    background-image: url(../themes/pattern.png);
    border-color: #0d1599; }

  &.wood {
    color: #fdff92;
    background-image: url(../themes/wood-tile.jpg);
    border-color: #e06500; }

  &.day {
    color: black;
    background-color: $day-color;
    border-color: darkgrey; }

  &.night {
    color: white;
    background-color: $night-color;
    border-color: lighten($night-color, 30%); } }
