$low-res: 650px;
$card-width: 16rem;
$def-border-radius: 15px;
$btn-bk-color: #eac214;
$btn-fg-color: #1b5d1b;

$wood-theme-color: #472000;
$day-theme-color: #ddd;
$night-theme-color: #111;

.amate {
  .index-container {
    background-image: url(../themes/tile.jpg);

    .index-card {
      background-image: url(../themes/pattern.png);
      border-color: #0d1599;
      box-shadow: 10px 10px 5px -6px grey;

      summary {
        color: brown; }

      img {
        border-color: darkgrey;
        background-color: #ffffff70; }

      p {
        background-color: #ffffff80; }

      .card-done {
        .memo {
          background-color: #ffffff80;
          border-color: darkgrey;

          .header {
            color: brown;
            background-color: #ffffff70;
            background-color: #ffffff80;
            background-image: url(../themes/pattern.png); } } } } } }

.wood {
  .index-container {
    background-color: darken($wood-theme-color, 10%);
    background-image: url(../themes/stone-tile.jpg);

    .index-card {
      background-color: $wood-theme-color;
      background-image: url(../themes/wood-tile.jpg);
      border-color: lighten($wood-theme-color, 20%);
      box-shadow: 10px 10px 5px -6px black;

      summary {
        color: #fdff92;
        background-color: #ffffff20;
        border-color: lighten($wood-theme-color, 30%); }

      img {
        background-color: lighten($wood-theme-color, 80%);
        border-color: lighten($wood-theme-color, 30%); }

      p {
        background-color: lighten($wood-theme-color, 65%);
        border-color: lighten($wood-theme-color, 30%); }

      .card-done {
        .memo {
          background-color: lighten($wood-theme-color, 75%);
          border-color: lighten($wood-theme-color, 30%);

          .header {
            color: #fdff92;
            background-color: $wood-theme-color;
            border-color: lighten($wood-theme-color, 30%);
            background-image: url(../themes/wood-tile.jpg); } } } } } }

.night {
  .index-container {
    background-color: darken($night-theme-color, 10%);
    background-image: url(../themes/night-texture.jpg);

    .index-card {
      background-color: $night-theme-color;
      border-color: lighten($night-theme-color, 20%);
      box-shadow: 10px 10px 5px -6px black;

      summary {
        color: white;
        background-color: #ffffff20;
        border-color: lighten($night-theme-color, 10%); }

      img {
        background-color: lighten($night-theme-color, 80%);
        border-color: lighten($night-theme-color, 10%); }

      p {
        background-color: lighten($night-theme-color, 65%);
        border-color: darken($night-theme-color, 10%); }

      .card-done {
        .memo {
          background-color: lighten($night-theme-color, 75%);
          border-color: darken($night-theme-color, 10%);

          .header {
            color: white;
            background-color: $night-theme-color;
            border-color: darken($night-theme-color, 10%);
            background-image: url(../themes/night-texture.jpg); } } } } } }

.day {
  .index-container {
    background-image: url(../themes/day-texture.jpg);

    .index-card {
      summary {
        color: black; } } } }

.index-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  background-color: darken($day-theme-color, 20%);
  padding-top: 2.7rem;
  min-height: 100vh;
  padding-bottom: 3rem;

  .card-unavailable {
    display: none; }

  .index-card {
    position: relative;
    width: $card-width;
    // min-height: $card-width * 1.42
    padding: 16px;
    margin: .5rem .5rem;
    background-color: $day-theme-color;
    border-radius: $def-border-radius;
    border: solid 2px darkgrey;

    @media only screen and (max-width: $low-res) {
      width: 90vw; }

    summary {
      color: red;
      background-color: #ffffff70;
      border: solid 2px darkgrey;
      border-radius: 10px;
      font-size: .9rem;
      font-weight: 500;
      min-height: 3em;
      padding: .1rem .5rem .2rem 1.5rem;
      cursor: pointer;
      user-select: none;
      outline: none;

      &::first-line {
        text-indent: -1rem; } }

    img {
      width: 100%;
      border-radius: 10px;
      border: solid 2px darkgrey;
      background-color: lighten($day-theme-color, 20%);
      margin-top: .5rem; }

    p {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      padding: 1rem;
      border-radius: $def-border-radius;
      border: solid 2px darkgrey;
      background-color: lighten($day-theme-color, 20%); }

    .memo {
      position: relative;
      // border: dotted thin red

      .btn {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 2rem;
        height: 2rem;
        color: $btn-fg-color;
        background-color: $btn-bk-color;
        border-color: darken($btn-bk-color, 5%);
        border-radius: $def-border-radius; }

      .done {
        bottom: 0;
        right: -0.75rem;
        font-size: 1.1rem; }

      .edit {
        font-size: 1rem;
        bottom: -0.75rem;
        right: -0.75rem; }

      textarea {
        box-sizing: border-box;
        padding: .5rem 1rem;
        margin: .5rem 0;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        border-radius: $def-border-radius; }

      .text-line {
        margin-top: .5em;
        margin-bottom: .5em;
        overflow-wrap: anywhere; } }

    .card-done {
      .memo {
        position: relative;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 0.8rem;
        font-style: italic;
        padding: 1rem;
        border-radius: $def-border-radius;
        border: solid 2px darkgrey;
        background-color: lighten($day-theme-color, 20%);

        .header {
          position: absolute;
          top: -14px;
          left: -3px;
          font-style: normal;
          font-weight: bold;
          border-radius: $def-border-radius;
          border: solid 2px darkgrey;
          padding: 3px 10px;
          color: black;
          background-color: lighten($day-theme-color, 20%); } } }

    .video-container {
      position: relative;
      left: 0;
      height: 0;
      width: 100%;
      padding-bottom: 56.25%;
      overflow: hidden;
      margin-bottom: 1rem;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $def-border-radius; } }

    .audio-container {
      position: relative;
      left: 0;
      height: 2rem;
      width: 100%;
      overflow: hidden;
      margin-bottom: 1rem; }

    .link-container {
      display: flex;
      justify-content: center;
      font-size: 1.2rem;

      a {
        color: black;
        text-decoration: none;
        font-weight: bold;
        margin-left: .5em; }

      &::before {
        content: '📚'; } } } }
